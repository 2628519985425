export enum ExternalRoutes {
  ALPACA_BCP_SUMMARY = 'https://files.alpaca.markets/disclosures/library/BCPSummary.pdf',
  ALPACA_CUSTOMER_AGREEMENT = 'https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf',
  ALPACA_EXTENDED_HOURS_RISK = 'https://files.alpaca.markets/disclosures/library/ExtHrsRisk.pdf',
  ALPACA_FORM_CRS = 'https://files.alpaca.markets/disclosures/library/FormCRS.pdf',
  ALPACA_MARGIN_DISCLOSURE_STATEMENT = 'https://files.alpaca.markets/disclosures/library/MarginDiscStmt.pdf',
  ALPACA_PFOF = 'https://files.alpaca.markets/disclosures/library/PFOF.pdf',
  ALPACA_PRIVACY_NOTICE = 'https://files.alpaca.markets/disclosures/library/PrivacyNotice.pdf',
  ALPACA_TERMS = 'https://files.alpaca.markets/disclosures/library/TermsAndConditions.pdf',
  ALPACA_USE_AND_RISK = 'https://files.alpaca.markets/disclosures/library/UseAndRisk.pdf',
  ATO = 'https://www.ato.gov.au',
  ATO_CLAIM_TAX_DEDUCTION = 'https://www.ato.gov.au/forms/notice-of-intent-to-claim-or-vary-a-deduction-for-personal-super-contributions',
  ATO_FHSS_SCHEME = 'https://www.ato.gov.au/individuals/super/withdrawing-and-using-your-super/first-home-super-saver-scheme',
  ATO_FORGOT_TFN = 'https://www.ato.gov.au/Individuals/Tax-file-number/Lost-or-stolen-TFN',
  ATO_TFN = 'https://www.ato.gov.au/individuals/tax-file-number',
  BASIQ = 'https://www.basiq.io',
  EARTH_SUSTAINABLE_DEVELOPMENT_GOALS = 'https://www.un.org/sustainabledevelopment/sustainable-development-goals',
  FALLBACK_APP_STORE_GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=au.com.spaceship.app.production',
  FALLBACK_APP_STORE_IOS = 'https://apps.apple.com/au/app/id1372650661',
  MEDIUM = 'https://medium.com/spaceship',
  MONEY_SMART_DEVELOP_AN_INVESTING_PLAN = 'https://moneysmart.gov.au/how-to-invest/develop-an-investing-plan',
  MONEY_SMART_INVESTING_AND_TAX = 'https://moneysmart.gov.au/how-to-invest/investing-and-tax',
  NOVA_W8BEN_FAQ_LINK = 'https://help.spaceship.com.au/en/collections/5454633-us-investing',
  OECD_TIN = 'https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers',
  SOCIAL_FACEBOOK = 'https://www.facebook.com/spaceshipau',
  SOCIAL_FACEBOOK_SHARE = 'https://www.facebook.com/sharer/sharer.php?u=',
  SOCIAL_INSTAGRAM = 'https://www.instagram.com/spaceshipau',
  SOCIAL_LINKEDIN = 'https://www.linkedin.com/company/spaceship-au',
  SOCIAL_LINKEDIN_SHARE = 'https://www.linkedin.com/sharing/share-offsite/?url=',
  SOCIAL_RSS_FEED = 'https://feedly.com/i/subscription/feed/https://www.spaceshipinvest.com.au/learn/rss',
  SOCIAL_TWITTER = 'https://twitter.com/spaceshipau',
  SOCIAL_TWITTER_SHARE = 'https://twitter.com/intent/tweet?url=',
  SPACESHIP_LOGIN = 'https://app.spaceship.com.au/login',
  SPACESHIP_SIGNUP = 'https://app.spaceship.com.au/signup',
  SUPER_ANNUAL_MEMBERS_MEETING_2024_RECORDING = 'https://youtu.be/QjwuX1yVSSA',
  SUPER_FSG = 'https://www.spaceship.com.au/documents/spaceship_fsg.pdf',
  SUPER_LOGIN = 'https://app.spaceship.com.au/login',
  SUPER_MY_GOV = 'https://my.gov.au/',
  SUPER_PDS = 'https://cdn.sargon.cloud/SS/c5ce9248-456c-4fdc-8da3-047e43b5c69d/Product%20Disclosure%20Statement.pdf',
  SPACESHIP_SUPER_THIRD_PARTY_CONTRIBUTION_FORM = 'https://www.spaceship.com.au/documents/spaceship_super_member_third_party_contribution_form.pdf',
  SUPER_REFERENCE_GUIDE = 'https://cdn.sargon.cloud/SS/dfb75128-b5e1-48c0-ab8c-6301e25e0ca5/Reference%20Guide.pdf',
  SUPER_ROLLOVER_REQUEST_FORM = 'https://www.ato.gov.au/uploadedFiles/Content/SPR/downloads/n75359_Request_for_rollover_of_whole_balance_form.pdf',
  SUPER_SIGNUP = 'https://app.spaceship.com.au/signup/super',
  SUPER_TMD = 'https://cdn.trusteecloud.com/2a80280a-0654-4a8c-9aab-39d672c1c1ae/target-market-determination-tmd-spaceship-super',
  SUPER_TRUSTEE_DOCUMENTS = 'https://diversa.com.au/governance',
  SUPPORT = 'https://help.spaceship.com.au',
  VOYAGER_LOGIN = 'https://app.spaceship.com.au/login',
  VOYAGER_SIGNUP = 'https://app.spaceship.com.au/signup/voyager',
  VOYAGER_TRANSACTIONS_FAQ = 'https://help.spaceship.com.au/en/collections/2310461-spaceship-voyager',
  ZEPTO_DEBIT_AGREEMENT = 'https://www.spaceship.com.au/documents/spaceship_zepto_direct_debit_request_service_agreement.pdf',
  VOYAGER_FEE_CHANGE_2024_NOTICE = 'https://www.spaceship.com.au/important-documents/fee-changes-290424/',
}
