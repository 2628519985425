import { ExternalRoutes, InternalRoutes } from '@spaceship-fspl/helpers';

export interface Document {
  name: string;
  link: string;
  isExternal?: boolean;
}

export const COMMON_DOCUMENTS: Document[] = [
  {
    name: 'Financial Services Guide',
    link: InternalRoutes.FINANCIAL_SERVICES_GUIDE,
  },
  {
    name: 'Privacy Policy and Privacy Collection Notice',
    link: InternalRoutes.PRIVACY_POLICY,
  },
  {
    name: 'App Terms and Conditions',
    link: InternalRoutes.APP_TERMS,
  },
  {
    name: 'Complaints Policy',
    link: InternalRoutes.COMPLAINTS_POLICY,
  },
];

export const VOYAGER_BASIC_DOCUMENTS: Document[] = [
  {
    name: 'Spaceship Universe Portfolio Product Disclosure Statement',
    link: InternalRoutes.VOYAGER_UNIVERSE_PDS,
  },
  {
    name: 'Spaceship Earth Portfolio Product Disclosure Statement',
    link: InternalRoutes.VOYAGER_EARTH_PDS,
  },
  {
    name: 'Spaceship Origin Portfolio Product Disclosure Statement',
    link: InternalRoutes.VOYAGER_ORIGIN_PDS,
  },
  {
    name: 'Spaceship Master Fund Product Disclosure Statement (for the Spaceship Galaxy Portfolio and Spaceship Explorer Portfolio)',
    link: InternalRoutes.VOYAGER_MASTER_FUND_PDS,
  },
  {
    name: 'Reference Guide',
    link: InternalRoutes.REFERENCE_GUIDE,
  },
  {
    name: 'Spaceship Universe Portfolio Target Market Determination',
    link: InternalRoutes.VOYAGER_UNIVERSE_TMD,
  },
  {
    name: 'Spaceship Earth Portfolio Target Market Determination',
    link: InternalRoutes.VOYAGER_EARTH_TMD,
  },
  {
    name: 'Spaceship Origin Portfolio Target Market Determination',
    link: InternalRoutes.VOYAGER_ORIGIN_TMD,
  },
  {
    name: 'Spaceship Master Fund Target Market Determination (for the Spaceship Galaxy Portfolio and Spaceship Explorer Portfolio)',
    link: InternalRoutes.VOYAGER_MASTER_FUND_TMD,
  },
];

export const VOYAGER_DOCUMENTS: Document[] = VOYAGER_BASIC_DOCUMENTS.concat(
  {
    name: 'Spaceship Universe Portfolio Financial Report (2024)',
    link: InternalRoutes.VOYAGER_UNIVERSE_FINANCIAL_REPORT_2024,
  },
  {
    name: 'Spaceship Origin Portfolio Financial Report (2024)',
    link: InternalRoutes.VOYAGER_ORIGIN_FINANCIAL_REPORT_2024,
  },
  {
    name: 'Spaceship Earth Portfolio Financial Report (2024)',
    link: InternalRoutes.VOYAGER_EARTH_FINANCIAL_REPORT_2024,
  },
  {
    name: 'Spaceship Universe Portfolio Financial Report (2023)',
    link: InternalRoutes.VOYAGER_UNIVERSE_FINANCIAL_REPORT_2023,
  },
  {
    name: 'Spaceship Origin Portfolio Financial Report (2023)',
    link: InternalRoutes.VOYAGER_ORIGIN_FINANCIAL_REPORT_2023,
  },
  {
    name: 'Spaceship Earth Portfolio Financial Report (2023)',
    link: InternalRoutes.VOYAGER_EARTH_FINANCIAL_REPORT_2023,
  },
  {
    name: 'Spaceship Universe Portfolio Financial Report (2022)',
    link: InternalRoutes.VOYAGER_UNIVERSE_FINANCIAL_REPORT_2022,
  },
  {
    name: 'Spaceship Origin Portfolio Financial Report (2022)',
    link: InternalRoutes.VOYAGER_ORIGIN_FINANCIAL_REPORT_2022,
  },
  {
    name: 'Spaceship Earth Portfolio Financial Report (2022)',
    link: InternalRoutes.VOYAGER_EARTH_FINANCIAL_REPORT_2022,
  },
  {
    name: 'Spaceship Universe Portfolio Financial Report (2021)',
    link: InternalRoutes.VOYAGER_UNIVERSE_FINANCIAL_REPORT_2021,
  },
  {
    name: 'Spaceship Origin Portfolio Financial Report (2021)',
    link: InternalRoutes.VOYAGER_ORIGIN_FINANCIAL_REPORT_2021,
  },
  {
    name: 'Spaceship Earth Portfolio Financial Report (2021)',
    link: InternalRoutes.VOYAGER_EARTH_FINANCIAL_REPORT_2021,
  },
  {
    name: 'Spaceship Universe Portfolio Financial Report (2020)',
    link: InternalRoutes.VOYAGER_UNIVERSE_FINANCIAL_REPORT_2020,
  },
  {
    name: 'Spaceship Origin Portfolio Financial Report (2020)',
    link: InternalRoutes.VOYAGER_ORIGIN_FINANCIAL_REPORT_2020,
  },
  {
    name: 'Spaceship Universe Portfolio Financial Report (2019)',
    link: InternalRoutes.VOYAGER_UNIVERSE_FINANCIAL_REPORT_2019,
  },
  {
    name: 'Spaceship Origin Portfolio Financial Report (2019)',
    link: InternalRoutes.VOYAGER_ORIGIN_FINANCIAL_REPORT_2019,
  },
  {
    name: 'Spaceship Universe Portfolio Financial Report (2018)',
    link: InternalRoutes.VOYAGER_UNIVERSE_FINANCIAL_REPORT_2018,
  },
  {
    name: 'Spaceship Origin Portfolio Financial Report (2018)',
    link: InternalRoutes.VOYAGER_ORIGIN_FINANCIAL_REPORT_2018,
  },
);

export const SUPER_BASIC_DOCUMENTS: Document[] = [
  {
    name: 'Product Disclosure Statement',
    link: ExternalRoutes.SUPER_PDS,
    isExternal: true,
  },
  {
    name: 'Reference Guide',
    link: ExternalRoutes.SUPER_REFERENCE_GUIDE,
    isExternal: true,
  },
  {
    name: 'Retirement Income Strategy Summary',
    link: InternalRoutes.SUPER_RETIREMENT_INCOME_STRATEGY_SUMMARY,
  },
  {
    name: 'Target Market Determination',
    link: ExternalRoutes.SUPER_TMD,
    isExternal: true,
  },
];

export const SUPER_DOCUMENTS: Document[] = SUPER_BASIC_DOCUMENTS.concat(
  {
    name: 'Letter of Compliance',
    link: InternalRoutes.SUPER_LETTER_OF_COMPLIANCE,
  },
  {
    name: 'Trustee Documents',
    link: ExternalRoutes.SUPER_TRUSTEE_DOCUMENTS,
    isExternal: true,
  },
  {
    name: 'Member Outcomes Assessment (2024)',
    link: InternalRoutes.SUPER_MEMBER_OUTCOMES_ASSESSMENT_2024,
  },
  {
    name: 'Portfolio Holdings Disclosure (31 December 2024)',
    link: InternalRoutes.SUPER_PORTFOLIO_HOLDINGS_DISCLOSURE_2024,
  },
  {
    name: 'Notice of 2024 Annual Member Meeting',
    link: InternalRoutes.SUPER_NOTICE_OF_ANNUAL_MEMBER_MEETING_2024,
  },
  {
    name: 'Annual Members’ Meeting 2024 minutes and Q&A',
    link: InternalRoutes.SUPER_ANNUAL_MEMBER_MEETING_2024,
  },
  {
    name: 'Annual Members’ Meeting 2024 recording',
    link: ExternalRoutes.SUPER_ANNUAL_MEMBERS_MEETING_2024_RECORDING,
    isExternal: true,
  },
  {
    name: 'Annual Report (2024)',
    link: InternalRoutes.SUPER_ANNUAL_REPORT_2024,
  },
);

export const SUPER_SUBSECTION_DOCUMENTS: Document[] = [
  {
    name: 'Significant Event Notice (29 May 2024)',
    link: InternalRoutes.SUPER_SIGNIFICANT_EVENT_NOTICE_MAY_2024,
  },
  {
    name: 'Notice of SRM change for Spaceship GrowthX (2 May 2024)',
    link: InternalRoutes.SUPER_PDS_UPDATE_MAY_2024,
  },
  {
    name: 'Significant Event Notice (15 April 2024)',
    link: InternalRoutes.SUPER_SIGNIFICANT_EVENT_NOTICE_APR_2024,
  },
  {
    name: 'Significant Event Notice (3 October 2023)',
    link: InternalRoutes.SUPER_SIGNIFICANT_EVENT_NOTICE_OCT_2023,
  },
  {
    name: 'Notice of fee change for Spaceship Super (September 2023)',
    link: InternalRoutes.SUPER_NOTICE_OF_FEE_CHANGE_SEPT_2023,
  },
  {
    name: 'Notice of investment changes for Spaceship Super (July 2023)',
    link: InternalRoutes.SUPER_NOTICE_OF_INVESTMENT_CHANGES_2023,
  },
];

export const NOVA_BASIC_DOCUMENTS: Document[] = [
  {
    name: 'Spaceship US Investing Service Terms and Conditions',
    link: InternalRoutes.NOVA_TERMS,
  },
  {
    name: 'Spaceship US Investing Service Fee Schedule',
    link: InternalRoutes.NOVA_FEE_SCHEDULE,
  },
];
